<template>
  <v-container style="max-width: 800px; position: relative;">
      <Header />
      <Confirmation />
      <Footer />
  </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import Confirmation from '../components/Confirmation.vue'
import Footer from '../components/Footer.vue'

export default {
    components: {
        Header,
        Confirmation,
        Footer
    },
    layout: 'report',
    mounted: function() {
      if(window.location.host == "vihjaa.fi") {
        window.location.replace("https://vihjaa.fi");
      }
    }
}
</script>

